export default {
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    button: {
      type: Object,
    },
  },

  components: {
    hotNewSocialProofCard: () => import('~/components/Molecules/NewSocialProofCard/NewSocialProofCard.vue'),
  },

  computed: {
    hasButton() {
      return this.button ? true : false
    },
  },
}
